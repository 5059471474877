.drawer-overlay {
  .drawer-content {
    display: flex;
    height: 100vh;
    right: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 999;
    background-color: #232323e6;
    cursor: url("../../img/mfg-close.png"), pointer;

    &.show {
      opacity: 1;
      visibility: visible;

      .drawer-filters {
        cursor: auto;
        transform: translateX(0);
      }
    }

    .drawer-filters {
      transform: translateX(100%);
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      background-color: #ffffff;
      justify-content: center;
      align-items: center;
      width: 30%;
      // height: 0;
      height: 100vh;
      transition: all 0.4s ease-in-out;
      cursor: auto;
    }
  }
}

// .drawer-content {
//   position: absolute;
//   background: white;
//   // padding: 20px;
//   top: 0;
//   right: 0;
//   // transform: translate(-50%, -50%);
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
// }
